<template>
  <div class="header">
    <Tooltip title="City" tooltipText="This widget shows the level of air pollution in the city"/>
    <div class="multiselect-wrapper">
      <Multiselect
        v-model="value"
        :options="options"
        :close-on-select="true"
        :searchable="true"
        :show-labels="false"
        :custom-label="customLabel"
        :showNoResults="false"
        :limit="3"
        placeholder="Find city..."
        label="city_normal"
        track-by="city_api"
        @select="selected"
        :preselect-first="false"
        :clear-on-select="true"
      >
      <pre class="language-json"><code>{{ value }}</code></pre>

       <template slot="singleLabel" slot-scope="props">
        <img class="option-image" :src="props.option.img">
        <div>
          <span>{{ cityName }} </span>
        </div>
      </template>
      </Multiselect>
    </div>

  </div>

</template>

<script>
import Multiselect from 'vue-multiselect';
import Tooltip from '@/components/UI/Tooltip.vue';
import cityData from '../../data/cityData.json';

export default {
  components: {
    Multiselect,
    Tooltip,
  },
  data() {
    return {
      options: cityData,
      value: { city_api: 'London', last: '2021-02-01T12:00:00+00:00', city_normal: 'London' },
    };
  },
  props: {
    name: {
      type: String,
      default: 'chart',
    },
    link: {
      type: String,
      default: '',
    },
  },
  computed: {
    cityName() {
      return this.value.city_normal.length > 8
        ? this.value.city_normal.slice(0, 8).concat('....')
        : this.value.city_normal;
    },
  },
  methods: {
    customLabel(cityNormal) {
      if (cityNormal.city_normal.length > 12) {
        return `${cityNormal.city_normal.slice(0, 12)}....`;
      }
      return `${cityNormal.city_normal}`;
    },
    selected(action) {
      this.$emit('getValue', action);
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
  display: flex;
  max-height: 57px;
  margin-bottom: 18px;

  justify-content: center;
}
h2 {
  font-family: 'Sawarabi Gothic';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 53px;
  text-align: center;

  color: #bfbfbf;
  text-shadow: 2px 2px 3px rgba(65, 61, 61, 0.25);
}

.multiselect-wrapper {
  position: absolute;
  right: 4px;
  top: 24px;
  z-index: 2;
}

.cancelComponent {
  display: none;
}

svg {
  width: 1em;
  cursor: pointer;
}

.svg__wrapper {
  position: absolute;
  right: 39px;
  top: 7px;
}

.svg__inner {
  position: absolute;
  top: 15px;
  right: 20px;
}
</style>

<style>
.multiselect-wrapper > .multiselect {
  color: #BFBFBF;
}

.multiselect > .multiselect__tags {
  min-height: 25px;
  width: 134px;
  height: 18px;
  display: block;
  border: 1px solid #737373 ;
  background: #303030 ;
  border-radius: 20px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 5px 12px 6px 12px ;
  font-size: 14px;
}

.multiselect > .multiselect__select {
  z-index: 99;
  right: -10px;
  top: -5px;
}

.multiselect__tags > .multiselect__single {
  line-height: 5px;
  min-height: 17px;
  height: 17px;
}

.multiselect__tags > .multiselect__input::placeholder {
  color: rgba(191, 191, 191, 0.2);
}

.multiselect > .multiselect__select:before {
  position: relative;
  right: 0px;
  top: 21px;
  color: #303030;
  margin-top: 4px;
  border-color: #505050 transparent transparent;
  border-style: solid;
  border-width: 7px 5px 0 ;
  z-index: 2;
  content: "";
}

.multiselect > .multiselect__select:after {
  position: relative;
  right: 12px;
  top: 23px;
  color: #303030;
  margin-top: 4px;
  border-color: #BFBFBF transparent transparent;
  border-style: solid;
  border-width: 10px 7px 1px;
  z-index: 1;
  content: "";
}

.multiselect > .multiselect__content-wrapper {
  margin-top: 3px;
}

.multiselect__content-wrapper > .multiselect__content {
  margin-top: 8px;
}

.multiselect__tags > .multiselect__input {
  background: #303030;
  max-width: 95px;
  height: 17px;
  min-height: 17px;
  line-height: 5px;
  padding-bottom: 4px;
  color: rgba(191, 191, 191, 1);
}

 .multiselect__tags > .multiselect__single {
  background: #303030;
  max-width: 95px;
  height: 17px;
  line-height: 5px;
  padding-top: 4px;
  color: rgba(191, 191, 191, 1);
}

.multiselect__tags > .multiselect__single span {
  font-family: 'Sawarabi Gothic';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
}

.multiselect__single span {
  position: absolute;
  top: 3.5px;
  left: 5.5px;
}

.multiselect > .multiselect__content-wrapper {
  max-width: 132px ;
  position: relative ;
  background: #303030 ;
  border: 1px solid rgba(115, 115, 115, 1) ;
  border-radius: 15px ;
  -webkit-overflow-scrolling: touch !important;
}

.multiselect__element > .multiselect__option--highlight {
  background: #3e3e3e ;
}

.multiselect__element > .multiselect__option--selected.multiselect__option--highlight {
  background: #3e3e3e ;
}

.multiselect__element > .multiselect__option--selected {
  color: rgba(191, 191, 191, 1) ;
  background: #303030 ;
  font-weight: 500;
}

.multiselect__content-wrapper::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  height: 8px;
  border-radius: 10px;
  background-color: #303030;
}

.multiselect__content-wrapper::-webkit-scrollbar
{
   display: none;
}

.multiselect__content-wrapper::-webkit-scrollbar-thumb
{
  height: 10px !important;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

.hide-scrollbar
{
    overflow: auto;
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}

.multiselect__element .multiselect__option {
  min-height: 14px;
  padding: 2.5px 0 4px 17px;
  line-height: 14px;
  margin-top: 3px;
}

.multiselect__single {
  white-space: nowrap;
  overflow: hidden;
}

.multiselect-wrapper > .multiselect--active {
  z-index: -50;
}

.multiselect-wrapper > .multiselect--active .multiselect__select {
  transform: rotateZ(0deg);
}

.multiselect-wrapper > .multiselect--active .multiselect__placeholder {
  display: none;
}

</style>
