import axios from 'axios';
import endpoint from '../constants/domain';

const axiosParams = {
  baseURL: endpoint.domain,
};

const axiosInstance = axios.create(axiosParams);

const api = (axios) => ({
  get: (url, config) => axios.get(url, config),
  post: (url, body, config) => axios.post(url, body, config),
  patch: (url, body, config) => axios.patch(url, body, config),
  delete: (url, config) => axios.delete(url, config),
});

export default api(axiosInstance);
