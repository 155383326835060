<script>
import { Bar } from 'vue-chartjs';

export default {
  name: 'Bar',
  extends: Bar,
  props: {
    chartData: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      gradient: null,
    };
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);
    this.gradientSecond = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);

    this.gradientThird = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);

    this.gradientFourth = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 480);

    for (let i = 0.001; i < 0.6; i += 0.001) {
      this.gradientThird.addColorStop(i, 'rgba(255, 204, 0, 1)');
    }
    this.gradientThird.addColorStop(0.83, 'rgba(59, 61, 59, 0.65)');
    this.gradientThird.addColorStop(0.9, 'rgba(59, 61, 59, 0.65)');
    this.gradientThird.addColorStop(0.95, 'rgba(59, 61, 59, 0.85)');
    this.gradientThird.addColorStop(1, 'rgba(59, 61, 59, 1)');

    for (let i = 0.001; i < 0.6; i += 0.001) {
      this.gradient.addColorStop(i, '#fe0000');
    }
    this.gradient.addColorStop(0.83, 'rgba(59, 61, 59, 0.65)');
    this.gradient.addColorStop(0.9, 'rgba(59, 61, 59, 0.65)');
    this.gradient.addColorStop(0.95, 'rgba(59, 61, 59, 0.85)');
    this.gradient.addColorStop(1, 'rgba(59, 61, 59, 1)');

    for (let i = 0.001; i < 0.6; i += 0.001) {
      this.gradientSecond.addColorStop(i, '#ff6600');
    }
    this.gradientSecond.addColorStop(0.83, 'rgba(59, 61, 59, 0.65)');
    this.gradientSecond.addColorStop(0.9, 'rgba(59, 61, 59, 0.65)');
    this.gradientSecond.addColorStop(0.95, 'rgba(59, 61, 59, 0.85)');
    this.gradientSecond.addColorStop(1, 'rgba(59, 61, 59, 1)');

    for (let i = 0.001; i < 0.7; i += 0.001) {
      this.gradientFourth.addColorStop(i, '#01CC00');
    }
    this.gradientFourth.addColorStop(0.83, 'rgba(59, 61, 59, 0.65)');
    this.gradientFourth.addColorStop(0.9, 'rgba(59, 61, 59, 0.65)');
    this.gradientFourth.addColorStop(0.95, 'rgba(59, 61, 59, 0.85)');
    this.gradientFourth.addColorStop(1, 'rgba(59, 61, 59, 1)');

    this.sortColorGradients(
      this.chartData.datasets[0].data,
      this.gradient,
      this.gradientSecond,
      this.gradientThird,
      this.gradientFourth,
    );
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    sortColorGradients(arr, colorFirst, colorSecond, cocolorThird, colourdFourth) {
      this.chartData.datasets[0].backgroundColor = [];
      const flag = arr.find((el) => el > 10) ? 1 : 0;
      arr.forEach((data) => {
        if (data > 300) {
          this.chartData.datasets[0].backgroundColor.push(colorFirst);
          return;
        }
        if (data > 100) {
          this.chartData.datasets[0].backgroundColor.push(colorSecond);
          return;
        }
        if (data > 50) {
          this.chartData.datasets[0].backgroundColor.push(cocolorThird);
          return;
        }
        if (data <= 10 && flag < 1) {
          this.chartData.datasets[0].backgroundColor.push('#188e18');
          return;
        }
        if (data <= 50) {
          this.chartData.datasets[0].backgroundColor.push(colourdFourth);
        }
      });
    },
  },
};
</script>

<style scoped></style>
