<template>
  <div class='template'>
    <aside>
      <div class='Chart__container'>
      <div class='Chart__title'>
        <WorldHeader :name='chartName' :average='conclusionQuality' />
      </div>
      <div class='chart__content'>
        <div v-if='loaded'>
          <div style="height:300px;">
           <BarChart :styles="myStyles" :chartData='chartData' :options='chartOptions' />
          </div>
          <div v-if='correct'>
          </div>
          <div class='reject-wrapper' v-else>
            <div class='reject-inner'>
               Unfortunately, there are currently no data on the World.
              <img src='./../assets/widgets/reject.svg' alt='reject'>
            </div>
          </div>
        </div>
        <Loader v-else />
      </div>
    </div>
      <div class='continents'>
        <Tooltip title='Continents' tooltipText='This widget shows the level
         of air pollution on the сontinents'/>
        <Gauages v-if='loaded' :gauagData='gauagData'  :colorGradient='colorGradient'/>
        <Loader v-else />
      </div>
    </aside>
  </div>
</template>

<script>
import BarChart from '@/components/BarChart.vue';
import WorldHeader from '@/components/WorldChart/WorldHeader';
import Loader from '@/components/UI/Loader.vue';
import Gauages from '@/components/WorldChart/Gauages.vue';
import Tooltip from '@/components/UI/Tooltip.vue';
import { fetchWorld } from '@/api/chartsApi';

export default {
  name: 'worldView',
  components: {
    BarChart,
    WorldHeader,
    Gauages,
    Loader,
    Tooltip,
  },
  data() {
    return {
      index: 0,
      loaded: false,
      correct: true,
      gradient: null,
      conclusionQuality: true,
      chartName: 'World',
      colorGradient: [],
      gauagData: {
        labels: [],
        data: [],
      },
      chartData: {
        labels: [
          '24 hours ago',
          '20 hours ago',
          '16 hours ago',
          '12 hours ago',
          '8 hours ago',
          '4 hours ago',
        ],
        datasets: [{
          data: [],
          backgroundColor: [],
          barThickness: 47,
        }],
      },
      myStyles: {
        height: '426px',
        width: '100%',
        position: 'relative',
      },
      chartOptions: {
        showScale: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 300,
              padding: 2,
              fontColor: '#BFBFBF',
              fontSize: 15,
              fontStyle: '500',
            },
            gridLines: {
              display: true,
              color: '#4b4b4b',
              drawTicks: false,
              borderDash: [5, 15],
            },
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
              padding: 8,
              fontColor: '#BFBFBF',
              fontSize: 12,
              fontStyle: '500',
            },
            gridLines: {
              display: false,
              color: '#EEF0F4',
              drawTicks: false,
              borderDash: [5, 15],
            },
          }],
        },
        elements: {
          borderColor: '#eb4034',
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(51, 51, 51, 0.9)',
          titleFontFamily: "'Sawarabi Gothic', sans-serif",
          titleFontColor: '#BFBFBF',
          titleFontSize: 12,
          titleFontStyle: 'normal',
          bodyFontFamily: "'Sawarabi Gothic', sans-serif",
          cornerRadius: 10,
          bodyFontColor: 'rgba(191, 191, 191, 1)',
          bodyFontSize: 12,
          borderColor: 'rgba(191, 191, 191, 1)',
          borderWidth: 1,
          titleAlign: 'center',
          bodyAlign: 'center',
          xPadding: 14,
          yPadding: 10,
          displayColors: false,
          mode: 'index',
          intersect: false,
          callbacks: {
            title: () => 'Air pollution level is',
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const currentValue = dataset.data[tooltipItem.index];
              return `${currentValue}`;
            },
          },
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const response = await fetchWorld();
      if (response.data.data === 0) {
        for (let i = 0; i < 6; i += 1) {
          this.chartData.datasets[0].data.push(0);
        }
        this.chartOptions.tooltips.enabled = false;
        this.correct = false;
        this.loaded = true;
      } else {
        this.gauagData.labels.push(...response.data.labels);
        this.gauagData.data.push(...response.data.data.continentArr);
        this.chartOptions.tooltips.enabled = true;
        this.chartData.datasets[0].data.push(...response.data.data.timeArr);
        this.conclusionQuality = this.getConclusionQuality(response.data.data.timeArr);
        this.getPoolColors(response.data.data.timeArr, this.gauagData.data);
      }
    },
    getPoolColors(arr, gaugeArr) {
      this.chartData.datasets[0].backgroundColor = [];
      this.gaugeArr = [];
      this.colorGradient = [];

      Object.values(gaugeArr).forEach((value) => {
        if ((value <= 50)) { this.colorGradient.push('good'); }
        if ((value > 50)) { this.colorGradient.push('normal'); }
        if ((value > 100)) { this.colorGradient.push('bad'); }
        if ((value > 200)) { this.colorGradient.push('danger'); }
      });
      this.loaded = true;
    },
    getConclusionQuality(iconData) {
      return (iconData.reduce((a, b) => (a + b)) / iconData.length) < 50;
    },
  },
};
</script>

<style scoped>
.template {
  background: #303030;
  margin-left: 20px;
}

.continents {
  font-family: 'Roboto', sans-serif;
  margin-top: 9px;
  height: 435px;
  width: 703px;
  border-top: 1px solid #737373;
  border-left: 1px solid #737373;
  border-bottom: 1px solid #737373;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.Chart__container {
  margin: 0px 0px;
  width: 626px;
  height: 493px;
  padding-left: 16px;
  padding-right: 40px;
  padding-bottom: 11px;

  background: #303030;
  border: 1px solid #737373;
  border-radius: 20px;
}

aside {
  max-width: 692px;
}

.reject-wrapper {
  transform: translateY(-300px) translateX(180px);
}

.reject-inner {
  background: #333333;
  position: relative;
  max-width: 187px;
  max-height: 112px;
  padding: 38px;

  border: 1px solid rgba(168, 145, 145, 0.36);
  border-radius: 10px;

  font-family: 'Sawarabi Gothic';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  transform: translateY(110px);
}

.reject-inner img {
  position: absolute;
  right: 4px;
  top: 9px;
}

.loading img {
  transform: translateX(22px);
}

</style>
