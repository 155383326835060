<template>
  <div class="widgets">
    <div>
      <div class="widget__wrapper">
        <div class="widget__inner">
          <div><img :src="getSvgUrl()" /></div>
          <div>{{ weatherWidgets.data[0] }} ℃</div>
        </div>
      </div>

      <div class="widget__wrapper">
        <div class="widget__inner">
          <div><img src="./../../assets/widgets/wind.svg" alt="wind" /></div>
          <div>
            {{ weatherWidgets.data[2] }}: {{ weatherWidgets.data[3] }} km/h
          </div>
        </div>
      </div>
    </div>

    <div>
      <div class="widget__wrapper">
        <div class="widget__inner">
          <div>
            <img src="./../../assets/widgets/humidity.svg" alt="humidity" />
          </div>
          <div>{{ weatherWidgets.data[1] }} %</div>
        </div>
      </div>
      <div class="widget__wrapper">
        <div class="widget__inner">
          <div><img src="./../../assets/widgets/gauage.svg" alt="gauage" /></div>
          <div>{{ weatherWidgets.data[4] }} hPa</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WeatherWidgets',
  props: {
    weatherWidgets: {
      type: Object,
      required: true,
    },
    weatherType: {
      type: String,
      default: 'Clear',
    },
  },
  data() {
    return {
      duration: 2000,
    };
  },
  methods: {
    getSvgUrl() {
      return require(`./../../assets/widgets/${this.weatherType}.svg`);
    },
    formatToData(value) {
      return `${Math.round(Number(value))}`;
    },
    formatToDataTemperature(value) {
      if (value >= 0) { return `+${Math.round(Number(value))}`; }
      return `${Math.round(Number(value))}`;
    },
  },
};
</script>
<style scoped>
.widgets {
  width: 682px;
  height: 382px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.widget__wrapper {
  margin: 0px auto;
}
.widget__inner {
  padding: 20px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #bfbfbf;
  text-shadow: 2px 2px 3px rgba(65, 61, 61, 0.25);
}
</style>
