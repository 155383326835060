<template>
  <div class="gauages">
    <div
      class="gauage__wrapper"
      v-for="(label, index) in gauagData.labels"
      :key="index"
    >
      <div :class="colorGradient[index]">
        <div class="gauage__inner">
          <div :class="label">{{ label }}</div>
          <div class="gauage__inner-number">{{ gauagData.data[index] }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Gauages',
  props: {
    gauagData: {
      type: Object,
      default: null,
    },
    colorGradient: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.gauages {
  width: 682px;
  height: 382px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.gauage__wrapper {
  margin: 20px;
}
.gauage__inner {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: auto;
  width: 81px;
  height: 81px;
  background: #303030;
  color: #bfbfbf;
  text-shadow: 2px 2px 3px rgba(65, 61, 61, 0.25);
  border-radius: 50%;

  text-align: center;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
}
.gauage__inner-number {
  margin: auto 0;
  font-family: "Rowdies";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}
.normal {
  display: table-cell;
  vertical-align: middle;
  width: 151px;
  height: 151px;
  background: linear-gradient(180deg, #FFCC00 0%, rgba(230, 249, 0, 0.05) 100%, rgba(59, 61, 59, 0.3) 100%);
  border-radius: 50%;
}
.good {
  display: table-cell;
  vertical-align: middle;
  width: 151px;
  height: 151px;
  background: linear-gradient(180deg, #01CC00 0%, rgba(59, 61, 59, 0.3) 100%);
  border-radius: 50%;
}
.bad {
  display: table-cell;
  vertical-align: middle;
  width: 151px;
  height: 151px;
  background: linear-gradient(180deg, #FF6600 0%, rgba(59, 61, 59, 0.3) 100%);
  border-radius: 50%;
}
.danger {
   display: table-cell;
  vertical-align: middle;
  width: 151px;
  height: 151px;
  background: linear-gradient(180deg, #FE0000 0%, rgba(59, 61, 59, 0.3) 100%);
  border-radius: 50%;
}
.Australia {
  margin: 12px 0 12px 0;
  transform:translateX(-5px) translateY(5px);
}
.Asia {
  margin: 12px 0 12px 0;
  transform:translateY(5px);
}
.Africa {
  margin: 12px 0 12px 0;
  transform:translateY(5px);
}
.Europe {
  margin: 12px 0 12px 0;
  transform:translateY(5px);
}
</style>
