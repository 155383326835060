<template>
<div class="header">
    <div class="balance"></div>
    <Tooltip title="World" tooltipText="This widget shows the level of air pollution in the world"/>
    <div class="tooltip-box">
      <div class="svg__wrapper">
        <img src="./../../assets/img/worldWrapper.svg" />
        <div class="svg__inner" v-if="average">
          <img src="./../../assets/img/worldGood.svg" />
        </div>
        <div v-else class="svg__inner">
          <img src="./../../assets/img/world.svg" />
        </div>
      </div>
      <div class="tooltip">
        The level of air pollution in the world:<br />
        <div class="tooltip__text-wrapper">
          <div>
            <div class="good"></div>
            <div>Good</div>
          </div>
          <div>
            <div class="moderate"></div>
            <div>Moderate</div>
          </div>
          <div>
            <div class="unhealthy"></div>
            <div>Unhealthy</div>
          </div>
          <div>
            <div class="dangerous"></div>
            <div>Dangerous</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Tooltip from '@/components/UI/Tooltip.vue';

export default {
  components: {
    Tooltip,
  },
  name: 'InformationButton',
  props: {
    name: {
      type: String,
      default: 'chart',
    },
    link: {
      type: String,
      default: '',
    },
    average: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
.title {
  display: flex;
  justify-content: center;
}
.icon {
  margin-top: 15px;
}
.header {
  position: relative;
  display: flex;
  max-height: 50px;
  margin-bottom: 18px;

  justify-content: center;
}
.balance {
  margin-right: auto;
  width: 80px;
}
.chart__title {
  display: flex;
  justify-content: center;
  max-height: 50px;
}
.tooltip__text-wrapper > div {
  display: flex;
  margin-bottom: 2px;
  justify-content: baseline;
}
.tooltip__text-wrapper {
  text-align: left;
}
.good {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-top: 4px;

  background: linear-gradient(180deg, #01cc00 0%, rgba(59, 61, 59, 0.3) 100%);
  transform: matrix(0, 1, 1, 0, 0, 0);
}
.unhealthy {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-top: 4px;

  background: linear-gradient(180deg, #ff6600 0%, rgba(59, 61, 59, 0.3) 100%);
  transform: matrix(0, 1, 1, 0, 0, 0);
}
.moderate {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-top: 4px;

  background: linear-gradient(
    180deg,
    #ffcc00 0%,
    rgba(59, 61, 59, 0.3) 99.99%,
    rgba(230, 249, 0, 0.05) 100%
  );
  transform: matrix(0, 1, 1, 0, 0, 0);
}
.dangerous {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin-top: 4px;

  background: linear-gradient(180deg, #fe0000 0%, rgba(59, 61, 59, 0.3) 100%);
  transform: matrix(0, 1, 1, 0, 0, 0);
}
.tooltip-info {
  position: relative;
  visibility: hidden;
  width: 191px;
  height: 35px;
  background: #333333;
  opacity: 0.8;
  border: 1px solid oldlace;
  border-radius: 10px;
  text-align: center;
  padding: 15px 11px 12px 11px;
  border-radius: 10px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #BFBFBF;

  left: 50%;
  transform: translateX(8px)  translateY(-1px);
  z-index: 1;
}
.tooltip-box-info:hover > .tooltip-info{
  visibility: visible;
}
.tooltip-box-info {
  position: relative;
  width: 15px;
  height: 15px;
 }

.tooltip {
  visibility: hidden;
  width: 236px;
  height: 104px;
  background: #333333;
  opacity: 0.8;
  border: 1px solid oldlace;
  border-radius: 10px;
  text-align: center;
  padding: 1px 7px 2px 7px;
  border-radius: 10px;
  bottom: calc(100% + 11px);

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #bfbfbf;

  transform: translateY(25px) translateX(-206px);
  left: 50%;
}
.svg__wrapper:hover + div {
  visibility: visible;
}
.tooltip-box {
  position: relative;
  width: 45px;
  height: 40px;
  margin: 14px 0 0 0;
  margin-left: auto;
}
h2 {
  width: 106px;
  height: 43px;
  margin: 12px 0 0 0;
  font-family: 'Sawarabi Gothic';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 53px;
  text-align: center;

  color: #bfbfbf;
  text-shadow: 2px 2px 3px rgba(65, 61, 61, 0.25);
}
.Chart__download {
  cursor: pointer;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 5px;
  text-decoration: none;
}
.Chart__download:hover {
  border-color: blue;
}

.svg__wrapper {
  position: absolute;
  right: -11px;
  top: -15px;
}
.svg__inner {
  position: absolute;
  top: 15px;
  right: 21px;
}
</style>
