<template>
  <main>
    <WorldView />
    <CityView />
  </main>
</template>

<script>
import WorldView from '@/views/worldView';
import CityView from '@/views/cityView';

export default {
  name: 'Dashboard',
  components: {
    WorldView,
    CityView,
  },
};
</script>

<style scoped>
main {
  display: flex;
  margin: 0px auto;
  max-width: 1440px;
  max-height: 1024px;
  justify-content: space-around;
}
</style>
