<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
};
</script>

<style>

@font-face {
  font-family: "Sawarabi Gothic";
  src: local("Sawarabi Gothic"),
   url(./assets/fonts/SawarabiGothic.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
   url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Rowdies";
  src: local("Rowdies"),
   url(./assets/fonts/Rowdies-Regular.ttf) format("truetype");
}
#app {
  font-family: 'Sawarabi Gothic', Helvetica, 'Roboto', 'Rowdies';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: #303030;
  color: #fff;
  height: 1024px;
  padding: 20px;
}
</style>
