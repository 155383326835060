<template>
  <div class='template'>
    <aside>
    <div class='Chart__container'>
      <div class='Chart__title'>
        <CityHeader v-on:getValue='getData($event)' :name='chartName' :link='linkComponent'/>
      </div>
      <div class='chart__content'>
        <div v-if='loaded'>
          <div style="height:300px;">
           <BarChart :styles="myStyles" :chartData='chartData' :options='chartOptions' />
          </div>
          <div v-if='correct'>
          </div>
          <div class='reject-wrapper' v-else>
            <div class='reject-inner'>
               Unfortunately, there are currently no data on the selected city.
              <img src='./../assets/widgets/reject.svg' alt='reject'>
            </div>
          </div>
        </div>
        <Loader v-else />
      </div>
    </div>
      <div class='continents'>
        <div class='title'>
          <div class='title-name'>Weather</div>
        </div>
        <WeatherWidgets v-if='loaded' :weatherWidgets='weatherWidgets' :weatherType='weatherType' />
        <Loader v-else />
      </div>
    </aside>
  </div>
</template>

<script>
import BarChart from '@/components/BarChart';
import CityHeader from '@/components/CityChart/CityHeader';
import WeatherWidgets from '@/components/CityChart/WeatherWidgets.vue';
import Loader from '@/components/UI/Loader.vue';
import { fetchCity } from '@/api/chartsApi';

export default {
  name: 'LineView',
  components: {
    BarChart,
    CityHeader,
    WeatherWidgets,
    Loader,
  },
  data() {
    return {
      loaded: false,
      correct: true,
      weatherType: 0,
      weatherWidgets: {
        labels: [],
        data: [],
      },
      linkComponent: 'yaho',
      chartName: 'City',
      chartData: {
        labels: [
          '24 hours ago',
          '20 hours ago',
          '16 hours ago',
          '12 hours ago',
          '8 hours ago',
          '4 hours ago',
        ],
        datasets: [{
          data: [],
          backgroundColor: [],
          barThickness: 47,
        }],
      },
      myStyles: {
        height: '426px',
        width: '100%',
        position: 'relative',
      },
      chartOptions: {
        showScale: true,
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              max: 300,
              padding: 2,
              fontColor: '#BFBFBF',
              fontSize: 15,
              fontStyle: '500',
            },
            gridLines: {
              display: true,
              color: '#4b4b4b',
              drawTicks: false,
              borderDash: [5, 15],
            },
          }],
          xAxes: [{
            ticks: {
              beginAtZero: true,
              padding: 8,
              fontColor: '#BFBFBF',
              fontSize: 12,
              fontStyle: '500',
            },
            gridLines: {
              display: false,
              color: '#EEF0F4',
              drawTicks: false,
              borderDash: [5, 15],
            },
          }],
        },
        tooltips: {
          enabled: true,
          backgroundColor: 'rgba(51, 51, 51, 0.9)',
          titleFontFamily: "'Sawarabi Gothic', sans-serif",
          titleFontColor: '#BFBFBF',
          titleFontSize: 12,
          titleFontStyle: 'normal',
          bodyFontFamily: "'Sawarabi Gothic', sans-serif",
          cornerRadius: 10,
          bodyFontColor: 'rgba(191, 191, 191, 1)',
          bodyFontSize: 12,
          borderColor: 'rgba(191, 191, 191, 1)',
          borderWidth: 1,
          titleAlign: 'center',
          bodyAlign: 'center',
          xPadding: 14,
          yPadding: 10,
          displayColors: false,
          mode: 'index',
          intersect: false,
          callbacks: {
            title: () => 'Air pollution level is',
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const currentValue = dataset.data[tooltipItem.index];
              return `${currentValue}`;
            },
          },
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData(action) {
      this.correct = true;
      this.chartData.datasets[0].data = [];
      this.loaded = false;
      this.weatherWidgets.data = [];
      const city = {
        city: action ? action.city_api : 'London',
      };
      const { data } = await fetchCity(city.city);
      if (data.status === 'good') {
        this.weatherWidgets.data.push(...data.weather);
        this.weatherType = data.weather[5];
        this.chartOptions.tooltips.enabled = true;
        this.chartData.datasets[0].data.push(...data.data);
        this.loaded = true;
      } else {
        this.weatherWidgets.data.push(...data.weather);
        this.weatherType = data.weather[5];
        for (let i = 0; i < 6; i += 1) {
          this.chartData.datasets[0].data.push(0);
        }
        this.chartOptions.tooltips.enabled = false;
        this.correct = false;
        this.loaded = true;
      }
    },
  },
};
</script>
<style src='vue-multiselect/dist/vue-multiselect.min.css'></style>
<style scoped>
.template {
  margin-right: 20px;
}

.continents {
  margin-top: 9px;
  height: 435px;
  border-top: 1px solid #737373;
  border-right: 1px solid #737373;
  border-bottom: 1px solid #737373;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.title {
  display: flex;
  justify-content: center;
  max-height: 50px;
}

.Chart__container {
  margin: 0px 0px;
  width: 626px;
  height: 493px;
  padding-left: 16px;
  padding-right: 40px;
  padding-bottom: 11px;

  background: #303030;
  border: 1px solid #737373;
  border-radius: 20px;
}

.chart__content {
  min-height: 430px;
  flex-grow: 1;
  min-height: 0;
}

.chart__content > div {
  position: relative;
  height: 100%;
}

.tooltip {
  position: absolute;
  visibility: hidden;
  width: 191px;
  height: 35px;
  background: #333333;
  opacity: 0.8;
  border: 1px solid oldlace;
  border-radius: 10px;
  text-align: center;
  padding: 15px 11px 12px 11px;
  border-radius: 10px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #BFBFBF;

  left: 50%;
  transform: translateX(10px)  translateY(-8px);
}

.tooltip-box:hover > .tooltip {
    visibility: visible;
}

.tooltip-box {
  position: relative;
  width: 30px;
}

aside {
  max-width: 770px;
}

.title-name {
  max-height: 43px;
  max-width: 178px;
  margin-top: 15px;
  font-family: 'Sawarabi Gothic';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 53px;
  text-align: center;

  color: #bfbfbf;
  text-shadow: 2px 2px 3px rgba(65, 61, 61, 0.25);
}

.reject-wrapper {
  transform: translateY(-300px) translateX(180px);

}

.reject-inner {
  background: #333333;
  position: relative;
  max-width: 187px;
  max-height: 112px;
  padding: 38px;

  border: 1px solid rgba(168, 145, 145, 0.36);
  border-radius: 10px;

  font-family: 'Sawarabi Gothic';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  transform: translateY(110px);
}

.reject-inner img {
  position: absolute;
  right: 4px;
  top: 9px;
}

.normal {
  display: table-cell;
  vertical-align: middle;
  width: 151px;
  height: 151px;
  background: linear-gradient(180deg, #10fd0c 0%, rgba(59, 61, 59, 0.85) 100%);
  border-radius: 50%;
}

.loading-bottom {
  position: relative;
  margin-top: 105px
}

</style>
