<template>
<div class="loading-bottom">
  <img  src="./../../assets/widgets/clock-1.svg" alt="clock">
  <img class="clock-1" src="./../../assets/widgets/clock-2.svg" alt="clock">
  <img class="clock-2" src="./../../assets/widgets/clock-3.svg" alt="clock">
  <div class="clock-arrow">
    <img class="rotating" src="./../../assets/widgets/clock-arrow.svg" alt="clock">
  </div>
</div>
</template>
<script>
export default {
};
</script>
<style scoped>
.loading-bottom {
  position: relative;
  margin-top: 105px
}
.clock-1 {
  position: absolute;
  top: -14.5px;
  left: 47.2%;
}

.clock-2 {
  position: absolute;
  left: 55.8%;
}

.clock-arrow {
  position: absolute;
  top: 14px;
  left: 49.7%;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-bl {
  0% {
    transform: rotate(0);
    transform-origin: 3.5px 40px;
  }
  100% {
    transform: rotate(360deg);
    transform-origin: 3.5px 40px;
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
 animation: rotate-bl 2s linear infinite both;
}
</style>
