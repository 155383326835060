<template>
  <div class='title'>
    <div class='title-name'>{{ title }}</div>
    <div class='tooltip-box'>
      <div class='icon'><img src='./../../assets/img/info.svg' /></div>
      <div class='tooltip'>
        {{ tooltipText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Tooltip',
  props: {
    title: {
      type: String,
      default: 'Chart',
    },
    tooltipText: {
      type: String,
      default: 'This widget shows the level of air pollution in the city',
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.title {
  display: flex;
  justify-content: center;
  max-height: 50px;
}
.title-name {
  margin-top: 15px;
  max-height: 43px;
  max-width: 178px;
  font-family: 'Sawarabi Gothic';
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 53px;
  text-align: center;

  color: #bfbfbf;
  text-shadow: 2px 2px 3px rgba(65, 61, 61, 0.25);
}
.tooltip {
  position: relative;
  visibility: hidden;
  width: 191px;
  height: 35px;
  background: #333333;
  opacity: 0.8;
  border: 1px solid oldlace;
  border-radius: 10px;
  text-align: center;
  padding: 15px 11px 12px 11px;
  border-radius: 10px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #BFBFBF;

  left: 50%;
  z-index: 3;
  transform: translateX(4px)  translateY(-8px);
}
.tooltip-box:hover > .tooltip {
  visibility: visible;
}
.tooltip-box {
  position: relative;
  width: 30px;
}
.icon {
  margin-top: 15px;
}
</style>
